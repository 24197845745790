* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
.login_title {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: Pacifico;
}
.login_form {
  height: 22vh;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_input {
  margin: 3% 0;
  height: 5vh;
  width: 75%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.form_icon {
  font-size: 1rem;
}
.form_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login_logo {
  display: none;
}
.login_options {
  height: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.options_forgetPassword {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 55%;
}
.login_line {
  width: 35%;
}
.login_forgetPassword {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}
.login_credentials {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 30vh;
}
.credentials_line {
  width: 70%;
}
.container {
  width: 100vw;
}

.swal-button1{
  background-color: #1d9df6;
  border: none;
  margin-left: 13vw;
}

@media (min-width: 720px) {
  .form_input {
    width: 40%;
  }
  .options_forgetPassword {
    width: 40%;
  }

  .form_container{
    margin-left: 50px;
  }

  .login_title{
    margin-top: 50px;
    margin-bottom: 23px;
  }

  .login_options{
    margin-top: 40px;
  }

  .form_btn{
    margin-top: 13px;
  }
}

@media (min-width: 1200px) {
  .login_image{
    width: 60vw;
    height: 100vh;
  }
  .login_logo {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .login_form{
    width: 50%;
  }
  .form_container{
    gap: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .form_input{
    width: 80%;
  }
  .container{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login_credentials {
    width: 50vw;
  }
  .credentials_line{
    width: 60%;
  }

  .login_options{
    margin-left: 70px;
    margin-top: 40px;
  }

  .login_forgetPassword{
    margin-left: 60px;
  }

  .login_title{
    margin-top: 80px;
    margin-bottom: 35px;
  }
}

@media (max-width: 420px) {

  .form_container{
    gap: 20px;
    
  }

  .form_btn{
    margin-left: 20px;
  }

  .login_options{
    margin-top: 40px;
  }

  .login_title{
    margin-top: 30px;
    margin-bottom: 26px;
  }
}