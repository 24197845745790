.nav_drawer{
  display: none;
}
.nav_bar{
  display: none;
}
@media (min-width: 720px) {
  .nav_drawer{
    display: flex;
  }
  .nav_bar{
    display: block;
  }
  .nav_menu-phone{
    display: none;
  }
}



#contenedor {
  position: relative;
  left: -60%;
  width: 90vw;
}
.loader {
  font-size: 13px;
  margin: 5% auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: -moz-linear-gradient(left, #cde5f5 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #cde5f5 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #cde5f5 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #cde5f5 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #cde5f5 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #cfe5f8;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #f5f5f5;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}