.cards {
  margin-bottom: 50px;
  display: flex;  
  gap: 40px;

  flex-wrap: wrap;
}
.cardInfo {
  border: 1px solid #1d9df6;
  height: 400px;
  border-radius: 4px;
  width: 530px;
}

.fecha{
/*   border: 1px solid #1d9df6; */
  width: 220px;
  margin-left: 300px;
  margin-top: -20px;
}


.inputsInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inputsDescription {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  margin-top: 100px;
  height: 40%;
}
.inputSignature {
  margin-top: 50px;
}

.inputSignature{
  display: flex;
  justify-content: crenter;
  align-items: center;
  gap:100px;
  width: 100%;

  margin-left: 30px;
}

.signTeacher{
  width: 250px;
  border: none;
  border-bottom: 2px solid #1d9df6;
}

.signStudent{
  width: 240px;
  margin-right:80px;
  border: none;
  border-bottom: 2px solid #1d9df6;

}


@media (max-width: 1200px){
  .cards {
    margin-bottom: 50px;
    display: flex;  
    gap: 40px;
    width: 41vw;
    flex-wrap: wrap;
  }
  .cardInfo {
    border: 1px solid #1d9df6;
    height: 400px;
    border-radius: 4px;
    width: 530px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 33px;
    margin-top: 50px;
  }
  
  
  .inputsInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .inputsDescription {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    height: 40%;
  }
  .inputSignature {
    margin-top: 50px;
  }
  
  .inputSignature{
    display: flex;
    justify-content: crenter;
    align-items: center;
    gap:100px;
    width: 100%;
  
    margin-left: 30px;
  }
  
  .signTeacher{
    width: 250px;
    border: none;
    border-bottom: 2px solid #1d9df6;
  }
  
  .signStudent{
    width: 240px;
    margin-right:80px;
    border: none;
    border-bottom: 2px solid #1d9df6;
  
  }

}

@media(max-width:420px){
  .cards {
    margin-bottom: 50px;
    display: flex;  
  }
  .cardInfo {
    border: 1px solid #1d9df6;
    height: 300px;
    border-radius: 4px;
    width: 370px;
  }

  .descriptionTeacher{
    width: 150px;
    height: 130px;
  }

  .descriptionStudent{
    width: 150px;
    height: 130px;

  }
  
  .fecha{
  /*   border: 1px solid #1d9df6; */
    width: 220px;
    margin-left: 140px;
    margin-top: -20px;
  }
  
  
  .inputsInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .inputsDescription {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    height: 40%;
  }
  .inputSignature {
    margin-top: 26px;
  }
  
  .inputSignature{
    display: flex;
    justify-content: crenter;
    align-items: center;
    gap:20px;

    width: 100%;
  
    margin-left: 10px;
  }
  
  .signTeacher{
    width: 150px;
    border: none;
    border-bottom: 2px solid #1d9df6;
  }
  
  .signStudent{
    width: 150px;
    margin-right:80px;
    border: none;
    border-bottom: 2px solid #1d9df6;
  
  }

}