.studentCard {
    display: flex;
    flex-wrap:wrap ;
    gap: 60px;
    width: 100%;
  
  
  }
    .history {
      display: flex;
      width: 200%;
      justify-content: center;
      margin-left: 200px;
    }
    
    .history h3 {
      font-size: 30px;
    }
    
    .FileSearch {
      margin-top: 14px;
      height: 50px;
      width: 50px;
    }


@media(max-width:420px){
  .history {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 250px;
  }

  .history h3 {
    font-size: 20px;
  }
  .FileSearch {
    margin-top: 7px;
    margin-left: 180px;
    height: 20px;
    width: 30px;
  }

}





@media(max-width:1200px){
  .studentCard {
    display: flex;
    flex-wrap:wrap ;
    gap: 60px;
  
  }
  
  
    .history {
      display: flex;
      width: 200%;
      justify-content: center;
      margin-left: 200px;
    }
    
    .history h3 {
      font-size: 30px;
    }
    
    .FileSearch {
      margin-top: 14px;
      height: 50px;
      width: 50px;
    }
}

  @media(max-width:780px) {
    .history {
        margin-left: -90px;
      }
  }

