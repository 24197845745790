/* container */
.mainRegister {
  position: relative;
  top: -25px;
}
.containerRegister{
  position: relative;
  left: 25px;
  width: 85%;
}

/* text register */
.Register {
  margin-top: 6%;
  font-size: 34px;
  font-family: "Pacifico";
  position: relative;
  right: -20%;
}

/*icon register */
.iconregister {
  color: #1d9df6;
  position: relative;
  top: -36px;
  left: 64%;
  width: 7%;
  height: 0%;
}

.lineRegister {
  border: 1px solid #1d9df6;
  width: 90%;
  margin: 10px 17px;
}

/*name y lastname*/
.last {
  display: flex;
  justify-content: space-between;
}

.name {
  border-radius: 5px;
  border: 1px solid #1d9df6;
  width: 40%;
  height: 25px;
}

.lastName {
  border-radius: 5px;
  border: 1px solid #1d9df6;
  width: 40%;
  height: 25px;
}

/*document identity  */
.containerDocement {
  padding-top: 3%;
}

.docement {
  border-radius: 5px;
  border: 1px solid #1d9df6;
  width: 100%;
  height: 25px;
}

/*data*/
.containerData {
  padding-top: 3%;
}

.date {
  border-radius: 5px;
  border: 1px solid #1d9df6;
  width: 100%;
  height: 25px;
  color: #949292;
}

/*upload image button*/
.photoRegister{
  padding-top: 3%;
}

.inputfile {
  width: 100%;
  height: 25px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 1em;
  color: #fff;
  background-color: #1d9df6;
}

.inputfile:focus,
.inputfile:hover {
  background-color: #949292;
}

/* container from select gender, role, group */
.containerSelect{
  padding-top: 3%;
  height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select {
  border-radius: 5px;
  border: 1px solid #1d9df6;
  width: 100%;
  height: 25px;
  color: #949292;
}

.message {
  color: #fa1200;
  font-size: 1rem;
  padding-left: 8px;
}

/* button register a new user */
.Registrar {
  color: rgb(255, 255, 255);
  background-color: #1d9df6;
  border: none;
  width: 105px;
  height: 35px;
  position: relative;
  top: 1rem;
  left: 12rem;
  border-radius: 5px;
}

@media (min-width: 720px) {
  .Register {
    margin-top: 4%;
    font-size: 41px;
    position: relative;
    right: -27%;
  }
  .iconregister {
    position: relative;
    top: -46px;
    left: 61%;
    width: 6%;
  }
  .containerRegister{
    left: 36px;
    width: 85%;
  }
  .lineRegister {
    width: 90%;
    margin: 8px 23px;
  }
  .name {
    height: 29px;
  }
  .lastName {
    height: 29px;
  }
  .docement {
    height: 29px;
  }
  .date {
    height: 29px;
  }
  .containerSelect{
    height: 130px;
  }
  .select {
    height: 29px;
  }
  .Registrar {
    top: 1rem;
    left: 18rem;
  }
}